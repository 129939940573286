* {
  margin: 0;
  padding: 0;
}

body {
  background: black;
  color: white; 
}

@font-face {font-family: "CopperplateGothic";
  font-weight: bold;
  src: url("./fonts/CopperplateGothicBold.eot"); /* IE9*/
  src: url("./fonts/CopperplateGothicBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/CopperplateGothicBold.woff2") format("woff2"), /* chrome、firefox */
  url("./fonts/CopperplateGothicBold.woff") format("woff"), /* chrome、firefox */
  url("./fonts/CopperplateGothicBold.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./fonts/CopperplateGothicBold.svg#Copperplate Gothic Bold V2") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "CopperplateGothic";
  font-weight: bolder;
  src: url("./fonts/CopperplateGothicHeavyBT.eot"); /* IE9*/
  src: url("./fonts/CopperplateGothicHeavyBT.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/CopperplateGothicHeavyBT.woff2") format("woff2"), /* chrome、firefox */
  url("./fonts/CopperplateGothicHeavyBT.woff") format("woff"), /* chrome、firefox */
  url("./fonts/CopperplateGothicHeavyBT.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("./fonts/CopperplateGothicHeavyBT.svg#Copperplate Gothic Bold V2") format("svg"); /* iOS 4.1- */
}