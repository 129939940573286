#tolja-splash-flex-container {

  background: black url('../../images/WineBackgroundImage.jpg') center top no-repeat;
}

#tolja-splash-img-poster {
  max-width: 90%;
  margin-top: 5%;
}

#tolja-splash-img-phone {
  max-width: 371px;
}

#nosuch-item {
  /*trigger build */
}

.tolja-splash-section {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 768px) {

  #tolja-splash-img-poster {
    max-width: 552px;
    margin-top: 5%;
  }
}

@media only screen and (min-width: 1200px) {
  .tolja-splash-section {
    margin: 0px 0px;
  }
  #tolja-splash-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  #tolja-splash-section-first {
    padding-top: 2em;
    margin-right: 10%;
  }
  #tolja-splash-section-second {
    padding-top: 2em;
  }
}

#tolja-splash-section-second {
  font-family: CopperplateGothic, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #e8dcb4;
  font-size: 2rem;
}

.tolja-hashtag {
  color: #f9a930;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 0.5em;
  margin-top: 1em;
}

.tolja-splash-marketing-copy {
  text-shadow: .05em .05em .1em rgba(0, 0, 0, 0.7);
}

.tolja-splash-marketing-pop {
  font-size: 1.2em;
  color:#feffff;
  margin-bottom: 0.5em;
} 
